$h6m: Helv-6-Medium, sans-serif;
$h5n: Helv-5-Normal, sans-serif;
$geb: Gilroy-ExtraBold, sans-serif;
$gl: Gilroy-Light, sans-serif;
$gb: GothicBold, sans-serif;
$gr: GothicRegular, sans-serif;
$ar: Arial, sans-serif;

/**
* Colors
**/

$accent-color: #52b444;
$common-color: #004f98;

.btn-outline-light:hover {
    color: $common-color;
    background-color: #fff;
    border-color: #f8f9fa;
}

.dib{
	display: inline-block;
	vertical-align: middle;
	position: relative;
	margin-right: 5px;
}

strong{
	font-family: $geb;
}