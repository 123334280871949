@charset "UTF-8";
/* Libs Style */
@import url(../../bower_components/bootstrap/dist/css/bootstrap.css);
@import url(../../bower_components/animate.css/animate.css);
@import url(../../bower_components/fancybox/dist/jquery.fancybox.css);
@import url(../../bower_components/jScrollPane/style/jquery.jscrollpane.css);
html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; } }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

/* Common style */
@font-face {
  font-family: Helv-6-Medium;
  /* Имя шрифта */
  src: url(../fonts/Helv-6-Medium.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: Helv-5-Normal;
  /* Имя шрифта */
  src: url(../fonts/Helv-5-Normal.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: Gilroy-ExtraBold;
  /* Имя шрифта */
  src: url(../fonts/Gilroy-ExtraBold.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: Gilroy-Light;
  /* Имя шрифта */
  src: url(../fonts/Gilroy-Light.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: GoodVibesPro;
  /* Имя шрифта */
  src: url(../fonts/GoodVibesPro.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: GothicBold;
  /* Имя шрифта */
  src: url(../fonts/GothicBold.ttf);
  /* Путь к файлу со шрифтом */ }

@font-face {
  font-family: GothicRegular;
  /* Имя шрифта */
  src: url(../fonts/GothicRegular.ttf);
  /* Путь к файлу со шрифтом */ }

/**
* Colors
**/
.btn-outline-light:hover {
  color: #004f98;
  background-color: #fff;
  border-color: #f8f9fa; }

.dib {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 5px; }

strong {
  font-family: Gilroy-ExtraBold, sans-serif; }

body {
  background-color: #e9f4ff;
  min-width: 320px;
  font-size: 16px;
  position: relative;
  font-family: Arial, sans-serif; }

button {
  outline: none; }

.top-line {
  position: absolute;
  width: 100%;
  z-index: 3; }
  .top-line__contact {
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    font-family: Gilroy-ExtraBold, sans-serif; }
    .top-line__contact .dib img {
      width: 100%; }
    .top-line__contact .dib .btn {
      margin-left: 10px;
      margin-right: 10px; }
    .top-line__contact .dib a {
      color: #fff;
      text-decoration: none; }
      .top-line__contact .dib a:hover {
        color: #004f98; }
  .top-line .top-mnu {
    position: relative; }
    .top-line .top-mnu ul {
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      -webkit-padding-start: 0px; }
      .top-line .top-mnu ul .disabled a {
        pointer-events: none; }
      .top-line .top-mnu ul li {
        position: relative; }
        .top-line .top-mnu ul li:hover::before {
          content: url("../img/icons/circle-shine.png");
          width: 30px;
          height: 30px;
          display: block;
          position: absolute;
          left: -30px;
          top: -2px; }
        .top-line .top-mnu ul li:hover a {
          color: #004f98; }
        .top-line .top-mnu ul li.active::before {
          content: url("../img/icons/circle-shine.png");
          width: 30px;
          height: 30px;
          display: block;
          position: absolute;
          left: -30px;
          top: -2px; }
        .top-line .top-mnu ul li a {
          text-transform: uppercase;
          font-family: Gilroy-ExtraBold, sans-serif;
          color: #fff;
          font-size: 18px;
          position: relative;
          text-decoration: none; }
        .top-line .top-mnu ul li:hover ul.inner-menu {
          visibility: visible;
          height: auto;
          padding: 5px 30px; }
          .top-line .top-mnu ul li:hover ul.inner-menu li a {
            font-size: 18px;
            pointer-events: auto; }
          .top-line .top-mnu ul li:hover ul.inner-menu li::before {
            top: 7px; }
        .top-line .top-mnu ul li ul.inner-menu {
          position: absolute;
          top: 30px;
          height: 0;
          padding: 0;
          display: block;
          visibility: hidden;
          background-color: #83b5bf; }
          .top-line .top-mnu ul li ul.inner-menu li {
            min-width: 100px;
            padding-top: 10px;
            padding-bottom: 10px; }
            .top-line .top-mnu ul li ul.inner-menu li a {
              font-size: 0; }
    .top-line .top-mnu::after {
      content: "";
      background-image: url("../img/icons/top-mnu-shadow.png");
      width: 100%;
      height: 25px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: block; }

.header {
  background-image: url(../img/header-bg.png);
  background-position: 50% 50%;
  background-size: cover;
  padding-top: 139px;
  padding-bottom: 150px;
  position: relative; }
  .header-title {
    font-size: 56px;
    line-height: 45px;
    text-transform: uppercase;
    color: #fff;
    opacity: .67;
    font-family: Gilroy-ExtraBold, sans-serif;
    text-align: center;
    margin-bottom: 0.6rem; }
  .header-form {
    width: 583px;
    height: 367px;
    margin: auto;
    background-image: url("../img/header-form-bg.png");
    background-size: 100% 100%;
    padding-right: 200px;
    padding-left: 20px;
    padding-top: 30px; }
    .header-form__text {
      font-size: 23px;
      line-height: 23px;
      font-family: GothicBold, sans-serif;
      color: #277684;
      text-align: center;
      text-shadow: 0 0 5px #ffffff;
      padding-left: 20px;
      padding-right: 20px; }
      .header-form__text span {
        font-size: 48px;
        line-height: 52px;
        color: #fff;
        text-shadow: 0 0 5px #277684;
        font-family: Gilroy-ExtraBold, sans-serif; }
      .header-form__text::after {
        content: url("../img/icons/header-form-arrow.png");
        width: 60px;
        height: 22px;
        margin: 5px auto;
        display: block; }
    .header-form__form {
      text-align: center; }
      .header-form__form input[type="text"] {
        width: 320px;
        height: 45px;
        background-image: url("../img/icons/input.png");
        background-size: 100% 100%;
        background-color: transparent;
        border: none;
        padding: 0 15px;
        margin-bottom: 10px;
        color: #fff; }
        .header-form__form input[type="text"]::placeholder {
          color: #fff; }
      .header-form__form input[type="submit"] {
        width: 230px;
        height: 44px;
        background-image: url("../img/icons/input-btn.png");
        background-size: 100% 100%;
        background-color: transparent;
        border: none;
        font-family: Gilroy-ExtraBold, sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        cursor: pointer;
        color: #fff; }
  .header-social {
    text-align: center;
    margin-top: 20px; }
  .header-search {
    text-align: center;
    margin-top: 10px; }
    .header-search input[type="text"] {
      width: 261px;
      height: 36px;
      background-color: transparent;
      border: none;
      padding: 5px 40px 0 15px;
      background-image: url("../img/icons/input-search.png");
      background-size: 100% 100%;
      color: #004f98;
      font-family: Arial, sans-serif; }
      .header-search input[type="text"]::placeholder {
        color: #004f98;
        font-family: Arial, sans-serif; }
    .header-search input[type="submit"] {
      background-image: url("../img/icons/search.png");
      width: 22px;
      height: 22px;
      background-color: transparent;
      border: none;
      background-size: 100% 100%;
      margin-left: -20px;
      position: relative;
      left: -15px;
      top: 5px; }
      .header-search input[type="submit"]:hover {
        cursor: pointer; }
  .header-img__aksa-yellow {
    position: absolute;
    bottom: 70px;
    left: 50px; }
  .header-img__aksa-dark {
    position: absolute;
    bottom: 70px;
    right: 50px; }

.products {
  margin-bottom: 20px; }
  .products .section-title {
    color: #004f98;
    font-size: 30px;
    font-family: Gilroy-ExtraBold, sans-serif;
    text-align: center;
    text-transform: uppercase; }
    .products .section-title span {
      background: #52b444;
      padding: 2px 5px;
      color: #fff; }
  .products-item__image {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    border: 5px #004f98 solid;
    padding: 40px 20px;
    position: relative;
    bottom: -60px; }
    .products-item__image img {
      width: 100%;
      max-width: 195px;
      max-height: 158px; }
    .products-item__image span {
      background: #52b444;
      color: #fff;
      font-size: 30px;
      font-family: Gilroy-ExtraBold, sans-serif;
      padding: 3px 30px;
      position: absolute;
      bottom: -15px;
      right: -30px; }
  .products-item__description {
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border: 2px #6f6f6f solid; }
    .products-item__description-btn {
      display: flex;
      justify-content: space-around;
      margin-top: 20px; }
      .products-item__description-btn a {
        background-image: url("../img/icons/product-btn.png");
        background-size: 100% 100%;
        height: 33px;
        padding: 5px 20px;
        color: #909090;
        text-transform: uppercase;
        font-family: Arial, sans-serif;
        font-weight: bold;
        text-decoration: none; }
        .products-item__description-btn a:hover {
          background-image: url("../img/icons/product-btn-hover.png");
          color: #fff;
          text-transform: uppercase; }
  .products-item .description {
    display: flex; }
    .products-item .description-item {
      font-size: 12px;
      padding: 0 5px; }
      .products-item .description-item:nth-child(1) {
        border-right: 1px #d5d5d5 solid; }
      .products-item .description-item:nth-child(2) {
        border-right: 1px #d5d5d5 solid; }
      .products-item .description-item:nth-child(3) {
        width: 40px;
        border-right: 1px #d5d5d5 solid; }
      .products-item .description-item__name {
        color: #004f98;
        font-family: Gilroy-ExtraBold, sans-serif;
        border-bottom: 1px #d5d5d5 solid;
        height: 30px;
        line-height: 12px; }
      .products-item .description-item__text {
        font-size: 10px; }
        .products-item .description-item__text .elem .dib {
          min-width: 30px; }

.category {
  margin-bottom: 20px; }
  .category .col-lg-3 {
    padding-left: 0;
    padding-right: 0; }
    .category .col-lg-3:nth-child(1) .category-item {
      background-color: #2e75a8; }
    .category .col-lg-3:nth-child(2) .category-item {
      background-color: #00b059; }
    .category .col-lg-3:nth-child(3) .category-item {
      background-color: #2e75a8; }
    .category .col-lg-3:nth-child(4) .category-item {
      background-color: #00b059; }
    .category .col-lg-3:nth-child(5) .category-item {
      background-color: #00b059; }
    .category .col-lg-3:nth-child(6) .category-item {
      background-color: #2e75a8; }
    .category .col-lg-3:nth-child(7) .category-item {
      background-color: #00b059; }
    .category .col-lg-3:nth-child(8) .category-item {
      background-color: #2e75a8; }
  .category-item {
    width: 100%;
    height: 285px;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column; }
    .category-item__title {
      display: block;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 23px;
      line-height: 23px;
      color: #fff;
      margin-bottom: auto; }
    .category-item__image {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: auto; }
      .category-item__image img {
        max-width: 100%;
        max-height: 130px; }
    .category-item__link {
      margin-top: auto;
      margin-bottom: 10px;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 18px;
      color: #fff;
      text-decoration: none; }
      .category-item__link:hover {
        color: #fff; }

.about {
  background-image: url("../img/about-bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding-top: 20px;
  display: flex;
  flex-direction: column; }
  .about .section-title {
    color: #fff;
    font-size: 30px;
    font-family: Gilroy-ExtraBold, sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin-left: -100px; }
    .about .section-title span {
      color: #52b444;
      padding: 2px 5px;
      background: #fff;
      margin-left: 150px; }
  .about-content {
    margin-top: auto;
    padding-bottom: 60px;
    padding-top: 60px;
    background-image: url("../img/about-shadow.png");
    background-position: 40% 50%;
    background-size: 120% 100%;
    color: #fff; }
    .about-content strong {
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 24px;
      line-height: 24px; }

.timeline {
  padding-top: 30px;
  padding-bottom: 30px; }
  .timeline .col-lg-6:nth-child(1) .timeline-item__text {
    padding-right: 180px;
    text-align: right; }
  .timeline .col-lg-6:nth-child(1) .timeline-item__year {
    background-image: url("../img/timeline-bg1.png");
    background-size: 100% 100%;
    right: 15px;
    padding-left: 60px; }
  .timeline .col-lg-6:nth-child(2) .timeline-item__text {
    padding-left: 180px;
    text-align: left; }
  .timeline .col-lg-6:nth-child(2) .timeline-item__year {
    background-image: url("../img/timeline-bg2.png");
    background-size: 100% 100%;
    left: 15px;
    padding-right: 60px;
    color: #2b2b2b; }
  .timeline .col-lg-6:nth-child(3) .timeline-item__text {
    padding-right: 180px;
    text-align: right; }
  .timeline .col-lg-6:nth-child(3) .timeline-item__year {
    background-image: url("../img/timeline-bg3.png");
    background-size: 100% 100%;
    right: 15px;
    padding-left: 60px; }
  .timeline .col-lg-6:nth-child(4) .timeline-item__text {
    padding-left: 180px;
    text-align: left; }
  .timeline .col-lg-6:nth-child(4) .timeline-item__year {
    background-image: url("../img/timeline-bg4.png");
    background-size: 100% 100%;
    left: 15px;
    padding-right: 60px;
    color: #2b2b2b; }
  .timeline-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    min-height: 164px; }
    .timeline-item__text {
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 20px;
      line-height: 20px;
      color: #2b2b2b; }
    .timeline-item__year {
      min-width: 212px;
      height: 164px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 40px;
      color: #fff;
      position: absolute; }
  .timelinefooter {
    margin-top: 20px;
    display: flex;
    justify-content: space-between; }
    .timelinefooter-item {
      max-width: 49%;
      display: flex;
      align-items: center;
      padding: 10px 15px; }
      .timelinefooter-item__text {
        max-width: 70%;
        font-size: 18px;
        line-height: 18px;
        color: #fff; }
      .timelinefooter-item:first-child {
        background-color: #004f98;
        justify-content: flex-end; }
        .timelinefooter-item:first-child .timelinefooter-item__text {
          text-align: right; }
        .timelinefooter-item:first-child .timelinefooter-item__image {
          margin-left: 15px; }
      .timelinefooter-item:last-child {
        background-color: #52b444; }
        .timelinefooter-item:last-child .timelinefooter-item__text {
          order: 2; }
        .timelinefooter-item:last-child .timelinefooter-item__image {
          margin-right: 15px;
          order: 1; }

.cta {
  display: flex;
  background-image: url("../img/cta-bg.png");
  background-size: cover; }
  .cta .content {
    margin-top: auto;
    margin-bottom: 30px;
    width: 100%; }
  .cta-form {
    background-image: url("../img/cta-form-bg.png");
    background-size: 100% 100%;
    width: 568px;
    height: 501px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-top: 110px;
    padding-left: 120px;
    padding-right: 120px;
    text-align: center; }
    .cta-form::before {
      content: url("../img/cta-form-left.png");
      position: absolute;
      left: -220px;
      top: 110px; }
    .cta-form::after {
      content: url("../img/cta-form-right.png");
      position: absolute;
      right: -220px;
      top: 110px; }
    .cta-form__text {
      color: #277684;
      font-size: 22px;
      line-height: 22px; }
    .cta-form__title {
      font-size: 30px;
      line-height: 30px;
      color: #fff;
      text-transform: uppercase;
      font-family: Gilroy-ExtraBold, sans-serif; }
      .cta-form__title::after {
        content: url(../img/icons/header-form-arrow.png);
        width: 60px;
        height: 22px;
        margin: 5px auto;
        display: block; }
    .cta-form__form {
      text-align: center; }
      .cta-form__form input[type="text"] {
        width: 320px;
        height: 45px;
        background-image: url("../img/icons/input.png");
        background-size: 100% 100%;
        background-color: transparent;
        border: none;
        padding: 0 15px;
        margin-bottom: 10px;
        color: #fff; }
        .cta-form__form input[type="text"]::placeholder {
          color: #fff; }
      .cta-form__form input[type="submit"] {
        width: 270px;
        height: 44px;
        background-image: url("../img/icons/input-btn.png");
        background-size: 100% 100%;
        background-color: transparent;
        border: none;
        font-family: Gilroy-ExtraBold, sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        cursor: pointer;
        color: #fff; }

.gallery {
  padding-bottom: 50px; }
  .gallery .link {
    display: block;
    margin-bottom: 15px; }
    .gallery .link img {
      width: 100%; }
  .gallery .section-title {
    color: #004f98;
    font-size: 30px;
    font-family: Gilroy-ExtraBold, sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin-left: -150px; }
    .gallery .section-title span {
      color: #fff;
      padding: 2px 5px;
      background: #52b444;
      margin-left: 300px; }
  .gallery .col-lg-3, .gallery .col-lg-9 {
    padding-left: 0;
    padding-right: 0;
    z-index: 99; }
  .gallery-previews {
    max-height: 465px;
    overflow-y: scroll;
    padding: 30px;
    background-color: #358be7; }
    .gallery-previews::-webkit-scrollbar {
      width: 1.2em; }
    .gallery-previews::-webkit-scrollbar-track {
      background-color: #26272b; }
    .gallery-previews::-webkit-scrollbar-thumb {
      background-color: #004f98;
      outline: 1px solid slategrey; }
    .gallery-previews::-webkit-scrollbar-button {
      background-color: #5c5d62;
      color: #fff; }
    .gallery-previews__item {
      display: block;
      margin-bottom: 5px; }
      .gallery-previews__item.active img {
        padding: 0;
        border: 5px solid #52b444; }
      .gallery-previews__item:hover img {
        padding: 0;
        border: 5px solid #52b444; }
      .gallery-previews__item img {
        padding: 5px;
        width: 100%; }
  .gallery-pagination {
    background: #025b8f;
    background: linear-gradient(to bottom, #025b8f 0%, #368abb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#025b8f', endColorstr='#368abb', GradientType=0 );
    border-top: 1px #000 solid;
    border-right: 1px #000 solid;
    padding: 10px 0; }
    .gallery-pagination ul {
      margin-bottom: 0;
      justify-content: center; }
      .gallery-pagination ul li:first-child a {
        background: #3b3b3b;
        background: linear-gradient(to bottom, #3b3b3b 0%, #222222 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3b3b', endColorstr='#222222', GradientType=0 );
        color: #6a6c72;
        font-size: 20px;
        line-height: 20px;
        font-family: Gilroy-ExtraBold, sans-serif;
        border-left: none; }
        .gallery-pagination ul li:first-child a:hover {
          color: #fff; }
      .gallery-pagination ul li:last-child a {
        background: #3b3b3b;
        background: linear-gradient(to bottom, #3b3b3b 0%, #222222 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3b3b', endColorstr='#222222', GradientType=0 );
        color: #6a6c72;
        font-size: 20px;
        line-height: 20px;
        font-family: Gilroy-ExtraBold, sans-serif; }
        .gallery-pagination ul li:last-child a:hover {
          color: #fff; }
      .gallery-pagination ul li a.active {
        background: #3b3b3b;
        background: linear-gradient(to bottom, #3b3b3b 0%, #222222 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3b3b', endColorstr='#222222', GradientType=0 );
        color: #fff; }
      .gallery-pagination ul li a {
        background-color: #242424;
        color: #6a6c72;
        border: none;
        border-left: #141414 solid 1px; }
        .gallery-pagination ul li a:hover {
          background: #3b3b3b;
          background: linear-gradient(to bottom, #3b3b3b 0%, #222222 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3b3b', endColorstr='#222222', GradientType=0 );
          color: #fff;
          border-color: #141414; }
        .gallery-pagination ul li a:focus {
          border-color: #141414; }
  .gallery-image {
    background: #358be7;
    text-align: center; }
    .gallery-image img {
      max-width: 100%;
      max-height: 465px;
      margin-left: auto;
      margin-right: auto; }
  .gallery-buttons {
    background: #025b8f;
    background: linear-gradient(to bottom, #025b8f 0%, #368abb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#025b8f', endColorstr='#368abb', GradientType=0 ); }
    .gallery-buttons a {
      padding: 18.5px;
      display: inline-block;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 20px;
      line-height: 20px;
      color: #1e1e1e; }
      .gallery-buttons a:hover {
        background-color: #373737;
        color: #fff;
        text-decoration: none; }
      .gallery-buttons a.active {
        background-color: #373737;
        color: #fff; }

.oblast {
  background-image: url("../img/oblast-bg.png");
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  z-index: 3; }
  .oblast .section-title {
    color: #fff;
    font-size: 30px;
    font-family: Gilroy-ExtraBold, sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin-left: -150px; }
    .oblast .section-title span {
      color: #52b444;
      padding: 2px 5px;
      background: #fff;
      margin-left: 300px; }
  .oblast-content {
    max-width: 80%;
    margin-left: auto;
    padding: 20px;
    margin-top: 30px;
    background-color: rgba(0, 0, 0, 0.34); }
    .oblast-content p {
      color: #fff;
      font-family: Gilroy-ExtraBold, sans-serif; }

.footer {
  margin-top: -50px;
  position: relative;
  z-index: 2; }
  .footer-map {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .footer .d-flex {
    height: 400px; }
  .footer-content {
    width: 70%;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    background-color: rgba(0, 0, 0, 0.34);
    padding: 60px;
    position: relative;
    z-index: 4;
    border: 4px #10b9da solid;
    border-right: none; }
    .footer-content__item:last-child {
      margin-top: 10px; }
      .footer-content__item:last-child a.btn {
        color: #fff;
        text-transform: uppercase;
        font-family: Gilroy-ExtraBold, sans-serif; }
        .footer-content__item:last-child a.btn:hover {
          background: #46ffff;
          border-color: #46ffff;
          color: #fff; }
      .footer-content__item:last-child .dib {
        margin-right: -5px; }
        .footer-content__item:last-child .dib:first-child {
          margin-right: 10px; }
    .footer-content::before {
      content: url("../img/corner-footer.png");
      position: absolute;
      top: -52px;
      left: -82px; }
    .footer-content__icon {
      min-width: 50px;
      text-align: center; }
    .footer-content__text {
      color: #fff;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 18px;
      line-height: 18px; }
      .footer-content__text a {
        color: #fff;
        font-family: Gilroy-ExtraBold, sans-serif;
        text-decoration: none;
        font-size: 18px;
        line-height: 18px; }
    .footer-content__button {
      margin-left: 10px; }

.copyright {
  background-color: #252626;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  color: #fff;
  font-family: Gilroy-ExtraBold, sans-serif; }
  .copyright a {
    color: #fff;
    text-decoration: none; }

.to-top {
  display: none; }

/**
* Pages
**/
.no-home .top-line {
  position: relative;
  background-image: url(../img/bg-top-line.png);
  background-size: cover;
  background-color: #fff; }

.no-home .footer {
  margin-top: 0; }

.no-home .pages {
  padding-top: 20px;
  padding-bottom: 50px; }
  .no-home .pages .section-title {
    color: #004f98;
    font-size: 30px;
    line-height: 35px;
    font-family: Gilroy-ExtraBold, sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin-left: -100px;
    margin-bottom: 30px; }
    .no-home .pages .section-title span {
      color: #fff;
      padding: 2px 5px;
      background: #52b444;
      margin-left: 180px; }
    .no-home .pages .section-title.two {
      margin-left: 0;
      margin-top: 20px; }
      .no-home .pages .section-title.two span {
        margin-left: 0; }
  .no-home .pages.services .content p {
    font-size: 18px;
    line-height: 20px;
    font-family: Gilroy-ExtraBold, sans-serif;
    color: #343434; }
  .no-home .pages .gallery .section-title {
    margin-left: -200px; }
    .no-home .pages .gallery .section-title span {
      margin-left: 300px; }
  .no-home .pages.page-about .content p {
    font-size: 18px;
    line-height: 20px; }
    .no-home .pages.page-about .content p strong {
      font-family: Gilroy-ExtraBold, sans-serif; }
  .no-home .pages.cart .cart-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .no-home .pages.cart .cart-items__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      margin: auto 1%;
      flex-wrap: wrap; }
      .no-home .pages.cart .cart-items__item .elem {
        flex: 1; }
        .no-home .pages.cart .cart-items__item .elem:first-child {
          position: relative;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 245px;
          max-height: 245px;
          padding: 35px;
          border: 5px #004f98 solid; }
          .no-home .pages.cart .cart-items__item .elem:first-child img {
            width: 100%; }
          .no-home .pages.cart .cart-items__item .elem:first-child span {
            position: absolute;
            bottom: -15px;
            right: -20px;
            font-size: 25px;
            font-family: Gilroy-ExtraBold, sans-serif;
            color: #fff;
            padding: 5px 20px;
            background-color: #52b444; }
        .no-home .pages.cart .cart-items__item .elem:last-child {
          padding: 15px;
          border-top: #6f6f6f solid 3px;
          border-right: #6f6f6f solid 3px;
          border-bottom: #6f6f6f solid 3px;
          text-align: center; }
          .no-home .pages.cart .cart-items__item .elem:last-child .title {
            margin-bottom: 10px;
            font-family: Arial, sans-serif;
            font-weight: bold;
            color: #909090;
            text-transform: uppercase;
            text-align: left; }
          .no-home .pages.cart .cart-items__item .elem:last-child input {
            margin-bottom: 10px;
            width: 80%;
            background-color: transparent;
            border: 3px #6f6f6f solid; }
          .no-home .pages.cart .cart-items__item .elem:last-child a {
            display: block;
            margin-bottom: 10px; }
            .no-home .pages.cart .cart-items__item .elem:last-child a img {
              width: 100%; }
            .no-home .pages.cart .cart-items__item .elem:last-child a:last-child {
              margin-bottom: 0; }
  .no-home .pages.page-catalog .section-pretitle {
    padding: 3px 0;
    text-align: center;
    background-color: #b9b9b9;
    width: 100%;
    color: #4b4b4b;
    font-size: 24px;
    font-family: Gilroy-ExtraBold, sans-serif;
    text-transform: uppercase; }
    .no-home .pages.page-catalog .section-pretitle span {
      color: #004f98; }
  .no-home .pages.page-catalog .catalog-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .no-home .pages.page-catalog .catalog-list__item {
      flex: 1;
      background-image: url(../img/icons/catalog-button.png);
      background-size: 100% 100%;
      min-width: 355px;
      min-height: 65px;
      max-width: 360px;
      padding-left: 70px;
      margin: 10px 5px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 18px;
      line-height: 20px;
      color: #004f98; }
    .no-home .pages.page-catalog .catalog-list__select {
      flex: 1px;
      margin: 30px 20px;
      border: 2px #52b444 solid;
      text-transform: uppercase;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 18px;
      color: #959595; }
  .no-home .pages.page-catalog .catalog3 table {
    font-family: Gilroy-ExtraBold, sans-serif;
    color: #004f98; }
    .no-home .pages.page-catalog .catalog3 table tr {
      vertical-align: middle; }
    .no-home .pages.page-catalog .catalog3 table th img {
      max-width: 25%; }
    .no-home .pages.page-catalog .catalog3 table a {
      display: block;
      margin-bottom: 5px; }
      .no-home .pages.page-catalog .catalog3 table a img {
        max-width: 100%; }
  .no-home .pages.page-catalog .catalog3 .desk {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;
    color: #909090;
    background-image: url(../img/butDis.png);
    background-size: 100% 100%;
    padding: 3px 0 3px 45px; }
  .no-home .pages.page-catalog .catalog3 .ord {
    text-transform: uppercase;
    font-size: 16px;
    color: white;
    background-image: url(../img/butOrd.png);
    background-size: 100% 100%;
    padding: 3px 0 3px 45px; }
  .no-home .pages.page-catalog .price-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    height: 15px;
    width: 100%;
    background-color: #52b444; }
    .no-home .pages.page-catalog .price-button a {
      text-transform: uppercase;
      color: #909090;
      font-size: 18px;
      font-family: Gilroy-ExtraBold;
      text-decoration: none;
      background-image: url(../img/butDis.png);
      background-size: 100% 100%;
      padding: 5px 10px 5px 45px; }
  .no-home .pages.page-catalog .product-slider {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center; }
    .no-home .pages.page-catalog .product-slider .item {
      margin-left: 5px;
      margin-right: 5px;
      background-color: #fff;
      border: 5px #004f98 solid;
      padding: 30px; }
      .no-home .pages.page-catalog .product-slider .item img {
        max-width: 100%; }
  .no-home .pages.page-catalog .product-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px; }
    .no-home .pages.page-catalog .product-button a {
      display: block;
      margin-right: 5px;
      margin-left: 5px; }
  .no-home .pages.page-catalog .productDesc-title {
    padding: 5px 30px;
    color: #fff;
    font-family: Gilroy-ExtraBold, sans-serif;
    font-size: 24px;
    background-color: #004f98;
    margin-bottom: 20px; }
  .no-home .pages.page-catalog .productDesc-description {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    font-family: Gilroy-ExtraBold, sans-serif;
    font-size: 18px;
    margin-bottom: 20px; }
    .no-home .pages.page-catalog .productDesc-description p {
      margin-bottom: 0;
      line-height: 18px; }
  .no-home .pages.page-catalog .productDesc table {
    margin-bottom: 1rem; }
    .no-home .pages.page-catalog .productDesc table tbody th img {
      max-width: 40%; }
  .no-home .pages.page-catalog .productDesc .table-description p {
    color: #5d5d5d;
    font-size: 18px;
    font-family: Gilroy-ExtraBold, sans-serif;
    margin-bottom: 1rem; }
    .no-home .pages.page-catalog .productDesc .table-description p span {
      color: #262728; }
  .no-home .pages .reviews-slider .owl-nav {
    text-align: center; }
    .no-home .pages .reviews-slider .owl-nav .owl-prev {
      margin: 20px 10px;
      color: #004f98;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 32px; }
      .no-home .pages .reviews-slider .owl-nav .owl-prev:hover {
        color: #52b444; }
    .no-home .pages .reviews-slider .owl-nav .owl-next {
      margin: 20px 10px;
      color: #004f98;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 32px; }
      .no-home .pages .reviews-slider .owl-nav .owl-next:hover {
        color: #52b444; }
  .no-home .pages .sertificates-slider .item img {
    border: 5px #52b444 solid; }
  .no-home .pages .sertificates-slider .owl-nav {
    text-align: center; }
    .no-home .pages .sertificates-slider .owl-nav .owl-prev {
      margin: 20px 10px;
      color: #004f98;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 32px; }
      .no-home .pages .sertificates-slider .owl-nav .owl-prev:hover {
        color: #52b444; }
    .no-home .pages .sertificates-slider .owl-nav .owl-next {
      margin: 20px 10px;
      color: #004f98;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 32px; }
      .no-home .pages .sertificates-slider .owl-nav .owl-next:hover {
        color: #52b444; }
  .no-home .pages .spec-slider {
    position: relative; }
    .no-home .pages .spec-slider .item {
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75); }
      .no-home .pages .spec-slider .item img {
        border: 5px #52b444 solid; }
    .no-home .pages .spec-slider .owl-nav .owl-prev {
      margin: 20px 10px;
      color: #52b444;
      font-family: Gilroy-ExtraBold, sans-serif;
      font-size: 32px;
      position: absolute;
      top: calc(50% - 50px);
      left: 50px; }
      .no-home .pages .spec-slider .owl-nav .owl-prev:hover {
        color: #004f98; }
    .no-home .pages .spec-slider .owl-nav .owl-next {
      margin: 20px 10px;
      font-family: Gilroy-ExtraBold, sans-serif;
      color: #52b444;
      font-size: 32px;
      position: absolute;
      top: calc(50% - 50px);
      right: 50px; }
      .no-home .pages .spec-slider .owl-nav .owl-next:hover {
        color: #004f98; }
  .no-home .pages .spec-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .no-home .pages .spec-block__image {
      flex: 1; }
      .no-home .pages .spec-block__image img {
        border: 5px #52b444 solid; }
    .no-home .pages .spec-block__text {
      flex: 2; }
      .no-home .pages .spec-block__text .title {
        font-size: 42px;
        color: #004f98;
        font-family: Gilroy-ExtraBold, sans-serif;
        line-height: 42px; }
      .no-home .pages .spec-block__text .pretitle {
        color: #004f98;
        font-size: 22px;
        font-family: Gilroy-ExtraBold, sans-serif;
        line-height: 22px; }
      .no-home .pages .spec-block__text p {
        color: #383838;
        font-family: Gilroy-ExtraBold, sans-serif;
        font-size: 18px;
        line-height: 18px; }
  .no-home .pages .filials-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px; }
  .no-home .pages .filials-map {
    flex: 2;
    margin-right: 50px;
    border: 5px #52b444 solid;
    box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.75); }
  .no-home .pages .filials-contacts {
    flex: 1; }
    .no-home .pages .filials-contacts__item_text {
      font-family: Gilroy-ExtraBold,sans-serif;
      font-size: 18px;
      line-height: 18px;
      color: #004f98; }
      .no-home .pages .filials-contacts__item_text a {
        text-decoration: none;
        color: #004f98; }
    .no-home .pages .filials-contacts__item_icon {
      min-width: 50px;
      text-align: center; }
  .no-home .pages .tech-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px; }
    .no-home .pages .tech-item__image {
      height: 370px;
      background-color: #52b444;
      display: flex;
      align-items: center; }
      .no-home .pages .tech-item__image img {
        max-width: 100%; }
    .no-home .pages .tech-item__title {
      font-size: 16px;
      color: #004f98;
      font-family: Gilroy-ExtraBold, sans-serif;
      margin: 10px 0; }
    .no-home .pages .tech-item__button {
      display: block; }
      .no-home .pages .tech-item__button img {
        max-width: 100%; }

.total {
  margin-top: 100px;
  font-family: Gilroy-ExtraBold, sans-serif;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .total span {
    color: #004f98; }

.navBurger {
  display: none;
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background: #111;
  box-sizing: border-box;
  background-clip: content-box;
  width: 30px;
  height: 24px;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 99999; }
  .navBurger:before, .navBurger:after {
    content: "";
    position: absolute;
    background: #111;
    height: 2px;
    width: 100%;
    right: 0;
    will-change: rotate;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  .navBurger:before {
    top: -10px; }
  .navBurger:after {
    bottom: -10px;
    width: 90%; }
  .navBurger.active {
    background: transparent;
    background-clip: content-box; }
  .navBurger.active:before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    top: 0;
    background: #eee;
    width: 110%; }
  .navBurger.active:after {
    bottom: 0;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    background: #eee;
    width: 110%; }

.mobile-menu {
  position: fixed;
  background: #2A3140;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform-origin: right top;
  transform: scale(0);
  visibility: hidden;
  transition: all .4s ease-in-out;
  overflow: hidden;
  z-index: 99999; }
  .mobile-menu.open {
    opacity: .99;
    visibility: visible;
    transform: scale(1); }
    .mobile-menu.open li {
      display: block;
      animation: fadeInRight 0.4s cubic-bezier(0.42, 0.83, 0.7, 1.5) forwards;
      animation-delay: .4s;
      transform: rotate(-7deg);
      transform-origin: right bottom; }
      .mobile-menu.open li:nth-of-type(2) {
        animation-delay: .5s; }
      .mobile-menu.open li:nth-of-type(3) {
        animation-delay: .6s; }
      .mobile-menu.open li:nth-of-type(4) {
        animation-delay: .7s; }
      .mobile-menu.open li:nth-of-type(5) {
        animation-delay: .8s; }

.overlayMenu {
  position: absolute;
  text-align: right;
  right: 10vw;
  top: 10vh;
  z-index: 9999; }
  .overlayMenu ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: block;
    position: relative;
    height: 100%; }
    .overlayMenu ul li {
      display: block;
      position: relative;
      opacity: 0;
      width: 100%; }
      .overlayMenu ul li .inner-menu {
        display: none; }
      .overlayMenu ul li:hover ul.inner-menu {
        display: block; }
      .overlayMenu ul li a {
        display: inline-block;
        border: 1px solid rgba(255, 255, 255, 0.09);
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        padding: 10px 40px 10px 40px;
        margin: 5px 0;
        transition: .2s; }
        .overlayMenu ul li a:hover, .overlayMenu ul li a:active, .overlayMenu ul li a:focus {
          transform: translateX(-2px);
          padding-left: 80px;
          outline: 1px solid rgba(255, 255, 255, 0.3);
          background: rgba(255, 255, 255, 0.03); }

.locked {
  overflow: hidden; }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 50px; }
  100% {
    opacity: 1;
    left: 0;
    transform: rotate(0deg); } }

.breadcrumb {
  display: flex; }
  .breadcrumb li {
    padding: 0 7.5px; }

.tcat .item {
  background-color: white;
  border: 1px solid #0066b1;
  box-shadow: 0px 0px 4px 0px #000000;
  margin: 5px 0; }

.tcat .title2 {
  color: #0066b1;
  font-family: Gilroy-ExtraBold;
  line-height: 1;
  margin: 20px 0;
  font-size: 19px; }

.tcat .pdf {
  text-decoration: none;
  text-transform: uppercase;
  font-family: Gilroy-ExtraBold;
  color: #ffffff;
  background-color: #52b444;
  box-shadow: 0 0 6px -1px #000;
  padding: 5px;
  transition: 0.5s linear; }
  .tcat .pdf:hover {
    background-color: #004f98; }

.tcat .title {
  color: #0066b1;
  font-family: Gilroy-ExtraBold; }

.tcat img {
  max-width: 100%; }

.tcat .desk {
  font-family: Gilroy-ExtraBold,sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  color: #909090;
  background-image: url(../img/butDis.png);
  background-size: 100% 100%;
  padding: 3px 0 3px 45px;
  width: 100%; }

.menu-scroll .disabled a {
  pointer-events: none; }

.menu-scroll .disabled .inner-menu a {
  pointer-events: auto; }

h1 {
  font-size: 28px; }

/* Medium Devices, Desktops */
@media only screen and (max-width: 1200px) {
  .header-img__aksa-yellow {
    left: -20px; }
  .header-img__aksa-dark {
    right: -20px; }
  .gallery .container::after {
    display: none; }
  .gallery-image {
    height: calc(100% - 57px); } }

@media only screen and (max-width: 1000px) {
  .header {
    overflow-x: hidden; }
  .header-img__aksa-yellow {
    max-width: 30%; }
  .header-img__aksa-dark {
    max-width: 30%; }
  .cta .content {
    overflow-x: hidden; } }

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .about {
    background-size: auto 100%; }
  .top-line .container .row .col-md-6 {
    order: 2;
    padding-left: 0;
    padding-right: 0; }
  .top-line .container .row .col-md-12 {
    order: 1; }
  .top-line .top-mnu {
    display: none; }
  .navBurger {
    display: block; }
  .category {
    margin-bottom: 20px; }
    .category .col-sm-6 {
      padding-left: 0;
      padding-right: 0; }
      .category .col-sm-6:nth-child(1) .category-item {
        background-color: #2e75a8; }
      .category .col-sm-6:nth-child(2) .category-item {
        background-color: #00b059; }
      .category .col-sm-6:nth-child(3) .category-item {
        background-color: #00b059; }
      .category .col-sm-6:nth-child(4) .category-item {
        background-color: #2e75a8; }
      .category .col-sm-6:nth-child(5) .category-item {
        background-color: #2e75a8; }
      .category .col-sm-6:nth-child(6) .category-item {
        background-color: #00b059; }
      .category .col-sm-6:nth-child(7) .category-item {
        background-color: #00b059; }
      .category .col-sm-6:nth-child(8) .category-item {
        background-color: #2e75a8; }
  .gallery-previews {
    max-height: 300px; }
  .oblast-content {
    max-width: 95%; }
  .no-home .pages.cart .cart-items__item {
    justify-content: center; }
    .no-home .pages.cart .cart-items__item .elem {
      flex: auto; }
      .no-home .pages.cart .cart-items__item .elem:last-child {
        border-left: #6f6f6f solid 3px; }
  .products-item__description-btn {
    flex-wrap: wrap; } }

@media only screen and (max-width: 768px) {
  .top-line__contact.text-right {
    text-align: center !important; }
  .top-line__contact {
    text-align: center;
    margin-bottom: 10px; }
  .top-line .container .row .col-md-12 img {
    max-height: 80px; }
  .gallery-previews {
    display: flex;
    overflow-y: visible;
    overflow-x: scroll; }
  .timelinefooter {
    flex-wrap: wrap; }
    .timelinefooter-item {
      max-width: 100%; }
  .footer-content {
    padding-left: 40px;
    padding-right: 0px; }
  .oblast-content {
    max-width: 100%; }
  .no-home .pages.cart .cart-items__item .elem:first-child span {
    right: auto;
    left: 50%;
    margin-left: -35%; }
  .no-home .pages .filials-map {
    flex: auto;
    width: 100%;
    margin-right: auto;
    margin-bottom: 20px; }
  .no-home .pages .filials-contacts {
    flex: auto;
    width: 100%; }
  .no-home .pages .filials-contacts__item_text {
    font-size: 14px; }
  .no-home .pages.page-catalog .productDesc table tbody th img {
    max-width: 80%; }
  .no-home .pages .spec-block__text {
    flex: auto; }
    .no-home .pages .spec-block__text .title {
      text-align: center; }
    .no-home .pages .spec-block__text .pretitle {
      text-align: center; }
  .no-home .pages .spec-block__image {
    flex: auto;
    margin-bottom: 20px;
    text-align: center; } }

@media only screen and (max-width: 576px) {
  .no-home .pages.cart .cart-items__item {
    flex: auto;
    margin-bottom: 20px; }
    .no-home .pages.cart .cart-items__item:last-child {
      margin-bottom: 0; }
    .no-home .pages.cart .cart-items__item .elem:last-child {
      max-width: 80%; }
  .category .col-sm-6:nth-child(3) .category-item {
    background-color: #2e75a8; }
  .category .col-sm-6:nth-child(4) .category-item {
    background-color: #00b059; }
  .category .col-sm-6:nth-child(5) .category-item {
    background-color: #2e75a8; }
  .category .col-sm-6:nth-child(6) .category-item {
    background-color: #00b059; }
  .category .col-sm-6:nth-child(7) .category-item {
    background-color: #2e75a8; }
  .category .col-sm-6:nth-child(8) .category-item {
    background-color: #00b059; }
  .about .section-title h1 {
    font-size: 17px; } }

@media only screen and (max-width: 480px) {
  .header {
    padding-top: 260px; }
    .header-title {
      font-size: 45px; }
    .header-form {
      width: 100%;
      height: auto;
      background-size: cover;
      padding-right: 20px;
      padding-bottom: 30px; }
      .header-form__form input[type="text"] {
        width: 100%; }
  .about {
    height: auto; }
  .timeline-item {
    flex-wrap: wrap; }
    .timeline-item__year {
      min-width: 100%;
      order: 1;
      position: relative;
      margin-bottom: 10px; }
    .timeline-item__text {
      order: 2;
      margin-bottom: 20px; }
  .timeline .col-lg-6:nth-child(1) .timeline-item__text {
    padding-right: 0;
    text-align: center; }
  .timeline .col-lg-6:nth-child(2) .timeline-item__text {
    padding-left: 0;
    text-align: center; }
  .timeline .col-lg-6:nth-child(3) .timeline-item__text {
    padding-right: 0;
    text-align: center; }
  .timeline .col-lg-6:nth-child(4) .timeline-item__text {
    padding-left: 0;
    text-align: center; }
  .oblast .section-title {
    margin-left: 0; }
    .oblast .section-title span {
      margin-left: 0; }
  .timelinefooter-item {
    flex-wrap: wrap; }
    .timelinefooter-item:first-child {
      justify-content: center; }
      .timelinefooter-item:first-child .timelinefooter-item__text {
        text-align: left;
        order: 2; }
      .timelinefooter-item:first-child .timelinefooter-item__image {
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        order: 1; }
    .timelinefooter-item:last-child {
      justify-content: center; }
      .timelinefooter-item:last-child .timelinefooter-item__text {
        text-align: left;
        order: 2; }
      .timelinefooter-item:last-child .timelinefooter-item__image {
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        order: 1; }
    .timelinefooter-item__text {
      max-width: 100%; }
    .timelinefooter-item__image {
      text-align: center; }
  .cta-form {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    background-image: none; }
    .cta-form::before {
      display: none; }
    .cta-form::after {
      display: none; }
    .cta-form__title {
      font-size: 25px;
      line-height: 25px; }
    .cta-form__text {
      font-size: 18px;
      color: #fff; }
    .cta-form__form input[type="text"] {
      width: 100%; }
  .gallery {
    padding-bottom: 0; }
    .gallery .section-title {
      font-size: 22px;
      margin-left: 0;
      margin-bottom: 20px; }
      .gallery .section-title span {
        margin-left: 0; }
    .gallery-previews__item {
      min-width: 50%; }
  .footer-map {
    position: relative; }
  .footer-content {
    width: 100%; }
  .no-home .pages.page-catalog .catalog-list__item {
    max-width: 100%;
    min-width: 100%; }
  .no-home .pages.page-catalog .catalog-list__item {
    font-size: 13px; } }
