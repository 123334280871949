body{
	background-color: #e9f4ff;
	min-width: 320px;
	font-size: 16px;
	position: relative;
	font-family: $ar;
}

button{
	outline: none;
}

.top-line{
	position: absolute;
	width: 100%;
	z-index: 3;
	&__contact{
		font-size: 14px;
		line-height: 14px;
		color: #fff;
		font-family: $geb;
		.dib{
			img{
				width: 100%;
			}
			.btn{
				margin-left: 10px;
				margin-right: 10px;
			}
			a{
				color: #fff;
				text-decoration: none;
				&:hover{
					color: $common-color;
				}
			}
		}
	}
	.top-mnu{
		position: relative;
		ul{
			list-style-type: none;
			display: flex;
			justify-content: space-between;
			margin-bottom: 0;
			-webkit-padding-start: 0px;
			.disabled{
				a{
					pointer-events: none;
				}
			}
			li{
				position: relative;
				&:hover{
					&::before{
						content: url("../img/icons/circle-shine.png");
						width: 30px;
						height: 30px;
						display: block;
						position: absolute;
						left: -30px;
						top: -2px;
					}
					a{
						color: #004f98;
					}
				}
				&.active{
					&::before{
						content: url("../img/icons/circle-shine.png");
						width: 30px;
						height: 30px;
						display: block;
						position: absolute;
						left: -30px;
						top: -2px;
					}
				}
				a{
					text-transform: uppercase;
					font-family: $geb;
					color: #fff;
					font-size: 18px;
					position: relative;
					text-decoration: none;
				}
				&:hover{
					ul.inner-menu{
						visibility: visible;
						height: auto;
						padding: 5px 30px;
						li{
							a{
								font-size: 18px;
								pointer-events: auto;
							}
							&::before{
								top: 7px;
							}
						}
					}
				}
				ul.inner-menu{
					position: absolute;
					top: 30px;
					height: 0;
					padding: 0;
					display: block;
					visibility: hidden;
					background-color: #83b5bf;
					li{
						min-width: 100px;
						padding-top: 10px;
						padding-bottom: 10px;
						a{
							font-size: 0;
						}
					}
				}
			}
		}
		&::after{
			content: "";
			background-image: url("../img/icons/top-mnu-shadow.png");
			width: 100%;
			height: 25px;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			display: block;
		}
	}
}

.header{
	background-image: url(../img/header-bg.png);
	background-position: 50% 50%;
	background-size: cover;
	padding-top: 139px;
	padding-bottom: 150px;
	position: relative;
	&-title{
		font-size: 56px;
		line-height: 45px;
		text-transform: uppercase;
		color: #fff;
		opacity: .67;
		font-family: $geb;
		text-align: center;
		margin-bottom: 0.6rem;
	}
	&-form{
		width: 583px;
		height: 367px; 
		margin: auto;
		background-image: url("../img/header-form-bg.png");
		background-size: 100% 100%;
		padding-right: 200px;
		padding-left: 20px;
		padding-top: 30px;
		&__text{
			font-size: 23px;
			line-height: 23px;
			font-family: $gb;
			color: #277684;
			text-align: center;
			text-shadow: 0 0 5px #ffffff;
			padding-left: 20px;
			padding-right: 20px;
			span{
				font-size: 48px;
				line-height: 52px;
				color: #fff;
				text-shadow: 0 0 5px #277684;
				font-family: $geb;
			}
			&::after{
				content: url("../img/icons/header-form-arrow.png");
				width: 60px;
				height: 22px;
				margin: 5px auto;
				display: block;
			}
		}
		&__form{
			text-align: center;
			input[type="text"]{
				width: 320px;
				height: 45px;
				background-image: url("../img/icons/input.png");
				background-size: 100% 100%;
				background-color: transparent;
				border: none;
				padding: 0 15px;
				margin-bottom: 10px;
				color: #fff;
				&::placeholder{
					color: #fff;
				}
			}
			input[type="submit"]{
				width: 230px;
				height: 44px;
				background-image: url("../img/icons/input-btn.png");
				background-size: 100% 100%;
				background-color: transparent;
				border: none;
				font-family: $geb;
				font-size: 18px;
				text-transform: uppercase;
				cursor: pointer;
				color: #fff;
			}
		}
	}
	&-social{
		text-align: center;
		margin-top: 20px;
	}
	&-search{
		text-align: center;
		margin-top: 10px;
		input[type="text"]{
			width: 261px;
			height: 36px;
			background-color: transparent;
			border: none;
			padding: 5px 40px 0 15px;
			background-image: url("../img/icons/input-search.png");
			background-size: 100% 100%;
			color: $common-color;
			font-family: $ar;
			&::placeholder{
				color: $common-color;
				font-family: $ar;
			}
		}
		input[type="submit"]{
			background-image: url("../img/icons/search.png");
			width: 22px;
			height: 22px;
			background-color: transparent;
			border: none;
			background-size: 100% 100%;
			margin-left: -20px;
			position: relative;
			left: -15px;
			top: 5px;
			&:hover{
				cursor: pointer;
			}
		}
	}
	&-img{
		&__aksa-yellow{
			position: absolute;
			bottom: 70px;
			left: 50px;
		}
		&__aksa-dark{
			position: absolute;
			bottom: 70px;
			right: 50px;
		}
	}
}

.products{
	margin-bottom: 20px;
	.section-title{
		color: $common-color;
		font-size: 30px;
		font-family: $geb;
		text-align: center;
		text-transform: uppercase;
		span{
			background: $accent-color;
			padding: 2px 5px;
			color: #fff;
		}
	}
	&-item{
		&__image{
			max-width: 70%;
			margin-left: auto;
			margin-right: auto;
			background: #fff;
			border: 5px $common-color solid;
			padding: 40px 20px;
			position: relative;
			bottom: -60px;
			img{
				width: 100%;
				max-width: 195px;
				max-height: 158px;
			}
			span{
				background: $accent-color;
				color: #fff;
				font-size: 30px;
				font-family: $geb;
				padding: 3px 30px;
				position: absolute;
				bottom: -15px;
				right: -30px;
			}
		}
		&__description{
			padding-top: 70px;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 20px;
			border: 2px #6f6f6f solid;
			&-btn{
				display: flex;
				justify-content: space-around;
				margin-top: 20px;
				a{
					background-image: url("../img/icons/product-btn.png");
					background-size: 100% 100%;
					height: 33px;
					padding: 5px 20px;
					color: #909090;
					text-transform: uppercase;
					font-family: $ar;
					font-weight: bold;
					text-decoration: none;
					&:hover{
						background-image: url("../img/icons/product-btn-hover.png");
						color: #fff;
						text-transform: uppercase;
					}
				}
			}
		}
		.description{
			display: flex;
			&-item{
				font-size: 12px;
				padding: 0 5px;
				&:nth-child(1){
					border-right: 1px #d5d5d5 solid;
				}
				&:nth-child(2){
					border-right: 1px #d5d5d5 solid;
				}
				&:nth-child(3){
					width: 40px;
					border-right: 1px #d5d5d5 solid;
				}
				&:nth-child(4){

				}
				&__name{
					color: $common-color;
					font-family: $geb;
					border-bottom: 1px #d5d5d5 solid;
					height: 30px;
					line-height: 12px;
				}
				&__text{
					font-size: 10px;
					.elem{
						.dib{
							min-width: 30px;
						}
					}
				}
			}
		}
	}
}

.category{
	margin-bottom: 20px;
	.col-lg-3{
		padding-left: 0;
		padding-right: 0;
		&:nth-child(1){
			.category-item{
				background-color: #2e75a8;
			}
		}
		&:nth-child(2){
			.category-item{
				background-color: #00b059;
			}
		}
		&:nth-child(3){
			.category-item{
				background-color: #2e75a8;
			}
		}
		&:nth-child(4){
			.category-item{
				background-color: #00b059;
			}
		}
		&:nth-child(5){
			.category-item{
				background-color: #00b059;
			}
		}
		&:nth-child(6){
			.category-item{
				background-color: #2e75a8;
			}
		}
		&:nth-child(7){
			.category-item{
				background-color: #00b059;
			}
		}
		&:nth-child(8){
			.category-item{
				background-color: #2e75a8;
			}
		}
	}
	&-item{
		width: 100%;
		height: 285px;
		padding: 15px;
		text-align: center;
		display: flex;
		flex-direction: column;
		&__title{
			display: block;
			font-family: $geb;
			font-size: 23px;
			line-height: 23px;
			color: #fff;
			margin-bottom: auto;
		}
		&__image{
			img{
				max-width: 100%;
				max-height: 130px;
			}
			padding-top: 20px;
			padding-bottom: 20px;
			margin-bottom: auto;
		}
		&__link{
			margin-top: auto;
			margin-bottom: 10px;
			font-family: $geb;
			font-size: 18px;
			color: #fff;
			text-decoration: none;
			&:hover{
				color: #fff;
			}
		}
	}
}

.about{
	background-image: url("../img/about-bg.png");
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	.section-title{
		color: #fff;
		font-size: 30px;
		font-family: $geb;
		text-align: center;
		text-transform: uppercase;
		margin-left: -100px;
		span{
			color: $accent-color;
			padding: 2px 5px;
			background: #fff;
			margin-left: 150px;
		}
	}
	&-content{
		margin-top: auto;
		padding-bottom: 60px;
		padding-top: 60px;
		background-image: url("../img/about-shadow.png");
		background-position: 40% 50%;
		background-size: 120% 100%;
		color: #fff;
		strong{
			font-family: $geb;
			font-size: 24px;
			line-height: 24px;
		}
	}
}

.timeline{
	padding-top: 30px;
	padding-bottom: 30px;
	.col-lg-6{
		&:nth-child(1){
			.timeline-item__text{
				padding-right: 180px;
				text-align: right;
			}
			.timeline-item__year{
				background-image: url("../img/timeline-bg1.png");
				background-size: 100% 100%;
				right: 15px;
				padding-left: 60px;
			}
		}
		&:nth-child(2){
			.timeline-item__text{
				padding-left: 180px;
				text-align: left;
			}
			.timeline-item__year{
				background-image: url("../img/timeline-bg2.png");
				background-size: 100% 100%;
				left: 15px;
				padding-right: 60px;
				color: #2b2b2b;
			}
		}
		&:nth-child(3){
			.timeline-item__text{
				padding-right: 180px;
				text-align: right;
			}
			.timeline-item__year{
				background-image: url("../img/timeline-bg3.png");
				background-size: 100% 100%;
				right: 15px;
				padding-left: 60px;
			}
		}
		&:nth-child(4){
			.timeline-item__text{
				padding-left: 180px;
				text-align: left;
			}
			.timeline-item__year{
				background-image: url("../img/timeline-bg4.png");
				background-size: 100% 100%;
				left: 15px;
				padding-right: 60px;
				color: #2b2b2b;
			}
		}
	}
	&-item{
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		min-height: 164px;
		&__text{
			font-family: $geb;
			font-size: 20px;
			line-height: 20px;
			color: #2b2b2b;
		}
		&__year{
			min-width: 212px;
			height: 164px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: $geb;
			font-size: 40px;
			color: #fff;
			position: absolute;
		}
	}
	&footer{
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		&-item{
			max-width: 49%;
			display: flex;
			align-items: center;
			padding: 10px 15px;
			&__text{
				max-width: 70%;
				font-size: 18px;
				line-height: 18px;
				color: #fff;
			}
			&:first-child{
				background-color: #004f98;
				justify-content: flex-end;
				.timelinefooter-item__text{
					text-align: right;
				}
				.timelinefooter-item__image{
					margin-left: 15px;
				}
			}
			&:last-child{
				background-color: #52b444;
				.timelinefooter-item__text{
					order: 2;
				}
				.timelinefooter-item__image{
					margin-right: 15px;
					order: 1;
				}
			}
		}
	}
}

.cta{
	display: flex;
	background-image: url("../img/cta-bg.png");
	background-size: cover;
	.content{
		margin-top: auto;
		margin-bottom: 30px;
		width: 100%;
	}
	&-form{
		background-image: url("../img/cta-form-bg.png");
		background-size: 100% 100%;
		width: 568px;
		height: 501px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		padding-top: 110px;
		padding-left: 120px;
		padding-right: 120px;
		text-align: center;
		&::before{
			content: url("../img/cta-form-left.png");
			position: absolute;
			left: -220px;
			top: 110px;
		}
		&::after{
			content: url("../img/cta-form-right.png");
			position: absolute;
			right: -220px;
			top: 110px;
		}
		&__text{
			color: #277684;
			font-size: 22px;
			line-height: 22px;
		}
		&__title{
			font-size: 30px;
			line-height: 30px;
			color: #fff;
			text-transform: uppercase;
			font-family: $geb;
			&::after{
				content: url(../img/icons/header-form-arrow.png);
				width: 60px;
				height: 22px;
				margin: 5px auto;
				display: block;
			}
		}
		&__form{
			text-align: center;
			input[type="text"]{
				width: 320px;
				height: 45px;
				background-image: url("../img/icons/input.png");
				background-size: 100% 100%;
				background-color: transparent;
				border: none;
				padding: 0 15px;
				margin-bottom: 10px;
				color: #fff;
				&::placeholder{
					color: #fff;
				}
			}
			input[type="submit"]{
				width: 270px;
				height: 44px;
				background-image: url("../img/icons/input-btn.png");
				background-size: 100% 100%;
				background-color: transparent;
				border: none;
				font-family: $geb;
				font-size: 18px;
				text-transform: uppercase;
				cursor: pointer;
				color: #fff;
			}
		}
	}
}

.gallery{
	padding-bottom: 50px;
	.link{
		display: block; 
		margin-bottom: 15px;
		img{
			width: 100%;
		}
	}
	.section-title{
		color: $common-color;
		font-size: 30px;
		font-family: $geb;
		text-align: center;
		text-transform: uppercase;
		margin-left: -150px;
		span{
			color: #fff;
			padding: 2px 5px;
			background: $accent-color;
			margin-left: 300px;
		}
	}
	.col-lg-3,.col-lg-9{
		padding-left: 0;
		padding-right: 0;
		z-index: 99;
	}
	&-previews{
		max-height: 465px;
		overflow-y: scroll;
		padding: 30px;
		background-color: #358be7;
		&::-webkit-scrollbar {
			width: 1.2em;
		}
		&::-webkit-scrollbar-track {
			background-color: #26272b;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $common-color;
			outline: 1px solid slategrey;
		}
		&::-webkit-scrollbar-button {
			background-color: #5c5d62;
			color: #fff;
		}
		&__item{
			display: block;
			margin-bottom: 5px;
			&.active{
				img{
					padding: 0;
					border: 5px solid $accent-color;
				}
			}
			&:hover{
				img{
					padding: 0;
					border: 5px solid $accent-color;
				}
			}
			img{
				padding: 5px;
				width: 100%;
			}
		}
	}
	&-pagination{
		background: rgba(2,91,143,1);
		background: linear-gradient(to bottom, rgba(2,91,143,1) 0%, rgba(54,138,187,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#025b8f', endColorstr='#368abb', GradientType=0 );
		border-top: 1px #000 solid;
		border-right: 1px #000 solid;
		padding: 10px 0;
		ul{
			margin-bottom: 0;
			justify-content: center;
			li{
				&:first-child{
					a{
						background: rgba(59,59,59,1);
						background: linear-gradient(to bottom, rgba(59,59,59,1) 0%, rgba(34,34,34,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3b3b', endColorstr='#222222', GradientType=0 );
						color: #6a6c72;
						font-size: 20px;
						line-height: 20px;
						font-family: $geb;
						border-left: none;
						&:hover{
							color: #fff;
						}
					}
				}
				&:last-child{
					a{
						background: rgba(59,59,59,1);
						background: linear-gradient(to bottom, rgba(59,59,59,1) 0%, rgba(34,34,34,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3b3b', endColorstr='#222222', GradientType=0 );
						color: #6a6c72;
						font-size: 20px;
						line-height: 20px;
						font-family: $geb;
						&:hover{
							color: #fff;
						}
					}
				}
				a.active{
					background: rgba(59,59,59,1);
					background: linear-gradient(to bottom, rgba(59,59,59,1) 0%, rgba(34,34,34,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3b3b', endColorstr='#222222', GradientType=0 );
					color: #fff;
				}
				a{
					background-color: #242424;
					color: #6a6c72;
					border: none;
					border-left: #141414 solid 1px;
					&:hover{
						background: rgba(59,59,59,1);
						background: linear-gradient(to bottom, rgba(59,59,59,1) 0%, rgba(34,34,34,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3b3b', endColorstr='#222222', GradientType=0 );
						color: #fff;
						border-color: #141414;
					}
					&:focus{
						border-color: #141414;
					}
				}
			}
		}
	}
	&-image{
		background: #358be7;
		text-align: center;
		img{
			max-width: 100%;
			max-height: 465px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&-buttons{
		background: rgba(2,91,143,1);
		background: linear-gradient(to bottom, rgba(2,91,143,1) 0%, rgba(54,138,187,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#025b8f', endColorstr='#368abb', GradientType=0 );
		a{
			padding: 18.5px;
			display: inline-block;
			font-family: $geb;
			font-size: 20px;
			line-height: 20px;
			color: #1e1e1e;
			&:hover{
				background-color: #373737;
				color: #fff;
				text-decoration: none;
			}
			&.active{
				background-color: #373737;
				color: #fff;
			}
		}
	}
}

.oblast{
	background-image: url("../img/oblast-bg.png");
	background-size: cover;
	background-position: 50% 50%;
	padding-top: 80px;
	padding-bottom: 80px;
	position: relative;
	z-index: 3;
	.section-title{
		color: #fff;
		font-size: 30px;
		font-family: $geb;
		text-align: center;
		text-transform: uppercase;
		margin-left: -150px;
		span{
			color: $accent-color;
			padding: 2px 5px;
			background: #fff;
			margin-left: 300px;
		}
	}
	&-content{
		max-width: 80%;
		margin-left: auto;
		padding: 20px;
		margin-top: 30px;
		background-color: rgba(0,0,0,.34);
		p{
			color: #fff;
			font-family: $geb;
		}
	}
}

.footer{
	margin-top: -50px;
	position: relative;
	z-index: 2;
	&-map{
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.d-flex{
		height: 400px;
	}
	&-content{
		width: 70%;
		margin-left: auto;
		margin-top: auto;
		margin-bottom: auto;
		background-color: rgba(0,0,0,0.34);
		padding: 60px;
		position: relative;
		z-index: 4;
		border: 4px #10b9da solid;
		border-right: none;
		&__item{
			&:last-child{
				margin-top: 10px;
				a.btn{
					color: #fff;
					text-transform: uppercase;
					font-family: $geb;
					&:hover{
						background: #46ffff;
						border-color: #46ffff;
						color: #fff;
					}
				}
				.dib{
					margin-right: -5px;
					&:first-child{
						margin-right: 10px;
					}
				}
			}
		}
		&::before{
			content: url("../img/corner-footer.png");
			position: absolute;
			top: -52px;
			left: -82px;
		}
		&__icon{
			min-width: 50px;
			text-align: center;
		}
		&__text{
			color: #fff;
			font-family: $geb;
			font-size: 18px;
			line-height: 18px;
			a{
				color: #fff;
				font-family: $geb;
				text-decoration: none;
				font-size: 18px;
				line-height: 18px;
			}
		}
		&__button{
			margin-left: 10px;
		}
	}
}

.copyright{
	background-color: #252626;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	padding: 10px 0;
	color: #fff;
	font-family: $geb;
	a{
		color: #fff;
		text-decoration: none;
	}
}

.to-top{
	display: none;
}

/**
* Pages
**/

.no-home{
	.top-line{
		position: relative;
		background-image: url(../img/bg-top-line.png);
		background-size: cover;
		background-color: #fff;
	}
	.footer{
		margin-top: 0;
	}

	.pages{
		padding-top: 20px;
		padding-bottom: 50px;
		.section-title{
			color: $common-color;
			font-size: 30px;
			line-height: 35px;
			font-family: $geb;
			text-align: center;
			text-transform: uppercase;
			margin-left: -100px;
			margin-bottom: 30px;
			span{
				color: #fff;
				padding: 2px 5px;
				background: $accent-color;
				margin-left: 180px;
			}
			&.two{
				margin-left: 0;
				margin-top: 20px;
				span{
					margin-left: 0;
				}
			}
		}
		&.services{
			.content{
				p{
					font-size: 18px;
					line-height: 20px;
					font-family: $geb;
					color: #343434;
				}
			}
		}
		.gallery{
			.section-title{
				margin-left: -200px;
				span{
					margin-left: 300px;
				}
			}
		}
		&.page-about{
			.content{
				p{
					font-size: 18px;
					line-height: 20px;
					strong{
						font-family: $geb;
					}
				}
			}
		}
		&.cart{
			.cart-items{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				&__item{
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex: 1;
					margin: auto 1%;
					flex-wrap: wrap;
					.elem{
						flex: 1;
						&:first-child{
							position: relative;
							background-color: #fff;
							display: flex;
							justify-content: center;
							align-items: center;
							max-width: 245px;
							max-height: 245px;
							padding: 35px;
							border: 5px $common-color solid;
							img{
								width: 100%;
							}
							span{
								position: absolute;
								bottom: -15px;
								right: -20px;
								font-size: 25px;
								font-family: $geb;
								color: #fff;
								padding: 5px 20px;
								background-color: $accent-color;
							}
						}
						&:last-child{
							padding: 15px;
							border-top: #6f6f6f solid 3px;
							border-right: #6f6f6f solid 3px;
							border-bottom: #6f6f6f solid 3px;
							text-align: center;
							.title{
								margin-bottom: 10px;
								font-family: $ar;
								font-weight: bold;
								color: #909090;
								text-transform: uppercase;
								text-align: left;
							}
							input{
								margin-bottom: 10px;
								width: 80%;
								background-color: transparent;
								border: 3px #6f6f6f solid;
							}
							a{
								display: block;
								margin-bottom: 10px;
								img{
									width: 100%;
								}
								&:last-child{
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
		&.page-catalog{
			.section-pretitle{
				padding: 3px 0;
				text-align: center;
				background-color: #b9b9b9;
				width: 100%;
				color: #4b4b4b;
				font-size: 24px;
				font-family: $geb;
				text-transform: uppercase;
				span{
					color: $common-color;
				}
			}
			.catalog-list{
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				&__item{
					flex: 1;
					background-image: url(../img/icons/catalog-button.png);
					background-size: 100% 100%;
					min-width: 355px;
					min-height: 65px;
					max-width: 360px;
					padding-left: 70px;
					margin: 10px 5px;
					display: flex;
					align-items: center;
					text-transform: uppercase;
					font-family: $geb;
					font-size: 18px;
					line-height: 20px;
					color: $common-color;
				}
				&__select{
					flex: 1px;
					margin: 30px 20px;
					border: 2px $accent-color solid;
					text-transform: uppercase;
					font-family: $geb;
					font-size: 18px;
					color: #959595;
				}
			}
			.catalog3{
				table{
					font-family: $geb;
					color: $common-color;
					tr{
						vertical-align: middle;
					}
					th{
						img{
							max-width: 25%;
						}
					}
					a{
						display: block;
						margin-bottom: 5px;
						img{
							max-width: 100%;
						}
					}
				}
				.desk{
					text-transform: uppercase;
					text-decoration: none;
					font-size: 16px;
					color: #909090;
					background-image: url(../img/butDis.png);
					background-size: 100% 100%;
					padding: 3px 0 3px 45px;
				}
				.ord{
					text-transform: uppercase;
					font-size: 16px;
					color: white;
					background-image: url(../img/butOrd.png);
					background-size: 100% 100%;
					padding: 3px 0 3px 45px;
				}
			}
			.price-button{
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 40px;
				height: 15px;
				width: 100%;
				background-color: $accent-color;
				a{
					text-transform: uppercase;
					color: #909090;
					font-size: 18px;
					font-family: Gilroy-ExtraBold;
					text-decoration: none;
					background-image: url(../img/butDis.png);
					background-size: 100% 100%;
					padding: 5px 10px 5px 45px;
				}
				
			} 
			.product-slider{
				margin-top: 30px;
				margin-bottom: 30px;
				display: flex;
				justify-content: center;
				.item{
					margin-left: 5px;
					margin-right: 5px;
					background-color: #fff;
					border: 5px $common-color solid;
					padding: 30px;
					img{
						max-width: 100%;
					}
				}
			}
			.product-button{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin-bottom: 30px;
				a{
					display: block;
					margin-right: 5px;
					margin-left: 5px;
				}
			}
			.productDesc{
				&-title{
					padding: 5px 30px;
					color: #fff;
					font-family: $geb;
					font-size: 24px;
					background-color: $common-color;
					margin-bottom: 20px;
				}
				&-description{
					max-width: 95%;
					margin-left: auto;
					margin-right: auto;
					font-family: $geb;
					font-size: 18px;
					margin-bottom: 20px;
					p{
						margin-bottom: 0;
						line-height: 18px;
					}
				}
				table{
					margin-bottom: 1rem;
					tbody{
						th{
							img{
								max-width: 40%;
							}
						}
					}
				}
				.table-description{
					p{
						color: #5d5d5d;
						font-size: 18px;
						font-family: $geb;
						margin-bottom: 1rem;
						span{
							color: #262728;
						}
					}
				}
			}
		}
		.reviews-slider{
			.owl-nav{
				text-align: center;
				.owl-prev{
					margin: 20px 10px;
					color: $common-color;
					font-family: $geb;
					font-size: 32px;
					&:hover{
						color: $accent-color;
					}
				}
				.owl-next{
					margin: 20px 10px;
					color: $common-color;
					font-family: $geb;
					font-size: 32px;
					&:hover{
						color: $accent-color;
					}
				}
			}
		}
		.sertificates-slider{
			.item{
				img{
					border: 5px $accent-color solid;
				}
			}
			.owl-nav{
				text-align: center;
				.owl-prev{
					margin: 20px 10px;
					color: $common-color;
					font-family: $geb;
					font-size: 32px;
					&:hover{
						color: $accent-color;
					}
				}
				.owl-next{
					margin: 20px 10px;
					color: $common-color;
					font-family: $geb;
					font-size: 32px;
					&:hover{
						color: $accent-color;
					}
				}
			}
		}
		.spec-slider{
			.item{
				box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
				img{
					border: 5px $accent-color solid;
				}
			}
			position: relative;
			.owl-nav{
				.owl-prev{
					margin: 20px 10px;
					color: $accent-color;
					font-family: $geb;
					font-size: 32px;
					position: absolute;
					top: calc(50% - 50px);
					left: 50px;
					&:hover{
						color: $common-color;
					}
				}
				.owl-next{
					margin: 20px 10px;
					font-family: $geb;
					color: $accent-color;
					font-size: 32px;
					position: absolute;
					top: calc(50% - 50px);
					right: 50px;
					&:hover{
						color: $common-color;
					}
				}
			}
		}
		.spec-block{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			&__image{
				flex: 1;
				img{
					border: 5px $accent-color solid;
				}
			}
			&__text{
				flex: 2;
				.title{
					font-size: 42px;
					color: $common-color;
					font-family: $geb;
					line-height: 42px;
				}
				.pretitle{
					color: $common-color;
					font-size: 22px;
					font-family: $geb;
					line-height: 22px;
				}
				p{
					color: #383838;
					font-family: $geb;
					font-size: 18px;
					line-height: 18px;
				}
			}
		}
		.filials{
			&-content{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				margin-bottom: 30px;
			}
			&-map{
				flex: 2;
				margin-right: 50px;
				border: 5px $accent-color solid;
				box-shadow: 0px 5px 11px 0px rgba(0,0,0,0.75);
			}
			&-contacts{
				flex: 1;
				&__item{
					&_text{
						font-family: Gilroy-ExtraBold,sans-serif;
						font-size: 18px;
						line-height: 18px;
						color: $common-color;
						a{
							text-decoration: none;
							color: $common-color;
						}
					}
					&_icon{
						min-width: 50px;
						text-align: center;
					}
				}
			}
		}

		.tech{
			&-item{
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 30px;
				&__image{
					height: 370px;
					background-color: $accent-color;
					display: flex;
					align-items: center;
					img{
						max-width: 100%;
					}
				}
				&__title{
					font-size: 16px;
					color: $common-color;
					font-family: $geb;
					margin: 10px 0;
				}
				&__button{
					display: block;
					img{
						max-width: 100%;
					}
				}
			}
		}
	}
}

.total{
	margin-top: 100px;
	font-family: $geb;
	font-size: 22px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	span{
		color: $common-color;
	}
}

.navBurger {
	display: none;
	position: fixed;
	right: 20px;
	top: 20px;
	cursor: pointer; 
	background: #111;
	box-sizing: border-box; 
	background-clip: content-box;
	width: 30px;
	height: 24px;
	border-top: 11px solid transparent;
	border-bottom: 11px solid transparent;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
	z-index: 99999;
	&:before,
	&:after {
		content: "";
		position: absolute;
		background: #111;
		height: 2px;
		width: 100%;
		right: 0;
		will-change: rotate;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
	&:before {
		top: -10px;
	}
	&:after {
		bottom: -10px;
		width: 90%;
	}
	&.active {
		background: transparent;
		background-clip: content-box;
	}
	&.active:before {
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
		top: 0;
		background: #eee;
		width: 110%;
	}
	&.active:after {
		bottom: 0;
		-webkit-transform: rotate(-135deg);
		transform: rotate(-135deg);
		background: #eee;
		width: 110%;
	}
}

.mobile-menu {
	position: fixed;
	background: #2A3140;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transform-origin: right top;
	transform: scale(0);
	visibility: hidden;
	transition: all .4s ease-in-out;
	overflow: hidden;
	z-index: 99999;
	&.open {
		opacity: .99;
		visibility: visible;
		// width: 100%;
		// height: 100%;
		transform: scale(1);
		li {
			display: block;
			animation: fadeInRight .4s cubic-bezier(.42, .83, .7, 1.5) forwards;
			animation-delay: .4s;
			transform: rotate(-7deg);
			transform-origin: right bottom;
			&:nth-of-type(2) {
				animation-delay: .5s;
			}
			&:nth-of-type(3) {
				animation-delay: .6s;
			}
			&:nth-of-type(4) {
				animation-delay: .7s;
			}
			&:nth-of-type(5) {
				animation-delay: .8s;
			}
		}
	}
}

.overlayMenu {
	position: absolute;
	text-align: right;
	right: 10vw;
	top: 10vh;
	z-index: 9999;
	ul {
		list-style: none;
		padding: 0;
		margin: 0 auto;
		display: block;
		position: relative;
		height: 100%;
		li {
			display: block;
			position: relative;
			opacity: 0;
			width: 100%;
			.inner-menu{
				display: none;
			}
			&:hover{
				ul.inner-menu{
					display: block;
				}
			}
			a {
				display: inline-block;
				border: 1px solid rgba(255,255,255,.09);
				color: #fff;
				text-decoration: none;
				font-size: 18px;
				padding: 10px 40px 10px 40px;
				margin: 5px 0;
				transition: .2s;
				&:hover,
				&:active,
				&:focus {
					transform: translateX(-2px);
					// outline-offset: 2px;
					padding-left: 80px;
					outline: 1px solid rgba(255,255,255,.3);
					background: rgba(255,255,255,.03);
				}
			}
		}
	}
}

.locked {
	overflow: hidden;
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		left: 50px;
	}
	100% {
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
	}
}

.breadcrumb{
	display: flex;
	li{
		padding: 0 7.5px;
	}
}

.tcat{
	.item{
		background-color: white;
		border: 1px solid #0066b1;
		box-shadow: 0px 0px 4px 0px #000000;
		margin: 5px 0;
	}
	.title2{
		color: #0066b1;
		font-family: Gilroy-ExtraBold;
		line-height: 1;
		margin: 20px 0;
		font-size: 19px;
	}
	.pdf{
		text-decoration: none;
		text-transform: uppercase;
		font-family: Gilroy-ExtraBold;
		color: #ffffff;
		background-color: #52b444;
		box-shadow: 0 0 6px -1px #000;
		padding: 5px;
		transition: 0.5s linear;
		&:hover{ 
			background-color: #004f98;
		}
	}
	.title{
		color: #0066b1;
		font-family: Gilroy-ExtraBold;
	} 
	img{
		max-width: 100%; 
	}
	.desk{
		font-family: Gilroy-ExtraBold,sans-serif;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 16px;
		color: #909090;
		background-image: url(../img/butDis.png);
		background-size: 100% 100%;
		padding: 3px 0 3px 45px;
		width: 100%;
	}
}
.menu-scroll{
	.disabled{
		a{
	    pointer-events: none;
	  }
	  .inner-menu{
	  	a{
	  		pointer-events: auto;
	  	}
	  }
	}
}

h1{font-size: 28px;}