/* Medium Devices, Desktops */
@media only screen and (max-width : 1200px) {
	.header-img__aksa-yellow{
		left: -20px;
	}
	.header-img__aksa-dark{
		right: -20px;
	}
	.gallery .container::after{
		display: none;
	}
	.gallery-image{
		height: calc(100% - 57px);
	}
}

@media only screen and (max-width : 1000px) {
	.header{
		overflow-x: hidden;
	}
	.header-img__aksa-yellow{
		max-width: 30%;
	}
	.header-img__aksa-dark{
		max-width: 30%;
	}
	.cta .content{
		overflow-x: hidden;
	}
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.about{
		background-size: auto 100%;
	}
	.top-line .container .row .col-md-6{
		order: 2;
		padding-left: 0;
		padding-right: 0;
	}
	.top-line .container .row .col-md-12{
		order: 1;
	}
	.top-line .top-mnu{
		display: none;
	}
	.navBurger{
		display: block;
	}
	.category{
		margin-bottom: 20px;
		.col-sm-6{
			padding-left: 0;
			padding-right: 0;
			&:nth-child(1){
				.category-item{
					background-color: #2e75a8;
				}
			}
			&:nth-child(2){
				.category-item{
					background-color: #00b059;
				}
			}
			&:nth-child(3){
				.category-item{
					background-color: #00b059;
				}
			}
			&:nth-child(4){
				.category-item{
					background-color: #2e75a8;
				}
			}
			&:nth-child(5){
				.category-item{
					background-color: #2e75a8;
				}
			}
			&:nth-child(6){
				.category-item{
					background-color: #00b059;
				}
			}
			&:nth-child(7){
				.category-item{
					background-color: #00b059;
				}
			}
			&:nth-child(8){
				.category-item{
					background-color: #2e75a8;
				}
			}
		}
	}
	.gallery-previews{
		max-height: 300px;
	}
	.oblast-content{
		max-width: 95%;
	}

	.no-home{
		.pages{
			&.cart{
				.cart-items{
					&__item{
						justify-content: center;
						.elem{
							flex: auto;
							&:last-child{
								border-left: #6f6f6f solid 3px;
							}
						}
					}
				}
			}
		}
	}

	.products-item__description-btn{
		flex-wrap: wrap;
	}

}

@media only screen and (max-width : 768px) {
	.top-line__contact.text-right{
		text-align: center !important;
	}
	.top-line__contact{
		text-align: center;
		margin-bottom: 10px;
	}
	.top-line .container .row .col-md-12{
		img{
			max-height: 80px;
		}
	}
	.gallery-previews{
		display: flex;
		overflow-y: visible;
		overflow-x: scroll;
	}
	.timelinefooter{
		flex-wrap: wrap;
		&-item{
			max-width: 100%;
		}
	}
	.footer-content{
		padding-left: 40px;
		padding-right: 0px;
	}
	.oblast-content{
		max-width: 100%;
	}

	.no-home{
		.pages{
			&.cart{
				.cart-items{
					&__item{
						.elem{
							&:first-child{
								span{
									right: auto;
									left: 50%;
									margin-left: -35%;
								}
							}
						}
					}
				}
			}
		}
	}
	.no-home .pages .filials-map{
		flex: auto;
		width: 100%;
		margin-right: auto;
		margin-bottom: 20px;
	}
	.no-home .pages .filials-contacts{
		flex: auto;
		width: 100%;
	}
	.no-home .pages .filials-contacts__item_text{
		font-size: 14px;
	}
	.no-home .pages.page-catalog .productDesc table tbody th img{
		max-width: 80%;
	}
	.no-home .pages .spec-block__text{
		flex: auto;
		.title{
			text-align: center;
		}
		.pretitle{
			text-align: center;
		}
	}
	.no-home .pages .spec-block__image{
		flex: auto;
		margin-bottom: 20px;
		text-align: center;
	}
}

@media only screen and (max-width : 576px) {
	.no-home{
		.pages{
			&.cart{
				.cart-items{
					&__item{
						flex: auto;
						margin-bottom: 20px;
						&:last-child{
							margin-bottom: 0;
						}
						.elem{
							&:last-child{
								max-width: 80%;
							}
						}
					}
				}
			}
		}
	}
	.category .col-sm-6:nth-child(3) .category-item{
		background-color: #2e75a8;
	}
	.category .col-sm-6:nth-child(4) .category-item{
		background-color: #00b059;
	}
	.category .col-sm-6:nth-child(5) .category-item{
		background-color: #2e75a8;
	}
	.category .col-sm-6:nth-child(6) .category-item{
		background-color: #00b059;
	}
	.category .col-sm-6:nth-child(7) .category-item{
		background-color: #2e75a8;
	}
	.category .col-sm-6:nth-child(8) .category-item{
		background-color: #00b059;
	}
	.about .section-title h1{
		font-size: 17px;
	}
}

@media only screen and (max-width : 480px) {
	.header{
		padding-top: 260px;
		&-title{
			font-size: 45px;
		}
		&-form{
			width: 100%;
			height: auto;
			background-size: cover;
			padding-right: 20px;
			padding-bottom: 30px;
			&__form input[type=text]{
				width: 100%;
			}
		}
	}
	.about{
		height: auto;
	}
	.timeline-item{
		flex-wrap: wrap;
		&__year{
			min-width: 100%;
			order: 1;
			position: relative;
			margin-bottom: 10px;
		}
		&__text{
			order: 2;
			margin-bottom: 20px;
		}
	}
	.timeline .col-lg-6:nth-child(1) .timeline-item__text{
		padding-right: 0;
		text-align: center;
	}
	.timeline .col-lg-6:nth-child(2) .timeline-item__text{
		padding-left: 0;
		text-align: center;
	}
	.timeline .col-lg-6:nth-child(3) .timeline-item__text{
		padding-right: 0;
		text-align: center;
	}
	.timeline .col-lg-6:nth-child(4) .timeline-item__text{
		padding-left: 0;
		text-align: center;
	}
	.oblast .section-title{
		margin-left: 0;
		span{
			margin-left: 0;
		}
	}
	.timelinefooter-item{
		flex-wrap: wrap;
		&:first-child{
			justify-content: center;
			.timelinefooter-item__text{
				text-align: left;
				order: 2;
			}
			.timelinefooter-item__image{
				margin-bottom: 20px;
				margin-left: auto;
				margin-right: auto;
				order: 1;
			}
		}
		&:last-child{
			justify-content: center;
			.timelinefooter-item__text{
				text-align: left;
				order: 2;
			}
			.timelinefooter-item__image{
				margin-bottom: 20px;
				margin-left: auto;
				margin-right: auto;
				order: 1;
			}
		}
		&__text{
			max-width: 100%;
		}
		&__image{
			text-align: center;
		}
	}
	.cta-form{
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		background-image: none;
		&::before{
			display: none;
		}
		&::after{
			display: none;
		}
		&__title{
			font-size: 25px;
			line-height: 25px;
		}
		&__text{
			font-size: 18px;
			color: #fff;
		}
		&__form input[type=text]{
			width: 100%;
		}
	}
	.gallery{
		padding-bottom: 0;
		.section-title{
			font-size: 22px;
			margin-left: 0;
			margin-bottom: 20px;
			span{
				margin-left: 0;
			}
		}
		&-previews{
			&__item{
				min-width: 50%;
			}
		}
	}
	.footer{
		&-map{
			position: relative;
		}
		&-content{
			width: 100%;
		}
	}

	.no-home .pages.page-catalog .catalog-list__item{
		max-width: 100%;
		min-width: 100%;
	}

	.no-home .pages.page-catalog .catalog-list__item{
		font-size: 13px;
	}
}