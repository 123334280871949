
/* Libs Style */
@import "../../bower_components/bootstrap/scss/bootstrap-reboot";
@import "../../bower_components/bootstrap/scss/utilities";
@import "../../bower_components/bootstrap/scss/grid";
@import "../../bower_components/bootstrap/dist/css/bootstrap.css";
@import "../../bower_components/bootstrap/scss/variables";
@import "../../bower_components/owl.carousel/src/scss/owl.carousel";
@import "../../bower_components/owl.carousel/src/scss/owl.theme.default";
@import "../../bower_components/animate.css/animate.css"; 
@import "../../bower_components/fancybox/dist/jquery.fancybox.css";
@import "../../bower_components/jScrollPane/style/jquery.jscrollpane.css";



/* Common style */

@import "partials/fonts";
@import "partials/variables";
@import "partials/app";
@import "partials/media"; 
